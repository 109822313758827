import GetBrowserNameFromUserAgent from 'Utils/GetBrowserNameFromUserAgent'

// all except ie
const isBrowserSupported = (browserName) => browserName !== 'Internet Explorer'

const CheckBrowserSupported = (userAgentHeader = null) => {
  // defaults to true (supported)
  let browserSupported = true

  // if user agent header present == server

  if (userAgentHeader) {
    const browserName = GetBrowserNameFromUserAgent(userAgentHeader)
    browserSupported = isBrowserSupported(browserName)
  } else if (typeof window !== 'undefined') {
    const browserName = GetBrowserNameFromUserAgent(window.navigator.userAgent)
    browserSupported = isBrowserSupported(browserName)
  }
  return browserSupported
}

export default CheckBrowserSupported
