import React from 'react'
import App from 'next/app'
import sentryService from 'Services/sentryService'
import globalsService from 'Services/globalsService'
import GetGlobals from 'Utils/GetGlobals'
import progressService from 'Services/progressService'
import mixpanelService from 'Services/mixpanelService'
import Head from 'next/head'
import Router, { withRouter } from 'next/router'
import CloseDialog from 'Utils/CloseDialog'
import CheckBrowserSupported from 'Utils/CheckBrowserSupported'
import Lazyload from 'Components/Lazyload'
import randomString from 'Utils/RandomString'
import GlobalsContext from 'Contexts/GlobalsContext'
import '../globals.css'

// IMPORTANT
// INLINE ALL THIRD PARTY CSS OTHERWISE WILL GET BUNDLED IN ONE SINGLE CSS FILE
// IMPORTANT

const promiseFinally = require('promise.prototype.finally')

promiseFinally.shim()

const LoginDialog = Lazyload(() => import('Components/LoginDialog'))
const Snackbar = Lazyload(() => import('Components/Snackbar'))
const ConfirmDialog = Lazyload(() => import('Components/ConfirmDialog'))
const NProgressCSS = Lazyload(() => import('Components/NProgressCSS'))
const UnderMaintenanceBlock = Lazyload(() =>
  import('Components/UnderMaintenanceBlock')
)
const AccountMovedBlock = Lazyload(() => import('Components/AccountMovedBlock'))
const BrowserNotSupportedBlock = Lazyload(() =>
  import('Components/BrowserNotSupportedBlock')
)
const AccountAccessRevoked = Lazyload(() =>
  import('Components/AccountAccessRevoked')
)

Router.events.on('routeChangeStart', () => {
  progressService.load()
})

Router.events.on('routeChangeComplete', () => {
  progressService.done()
  const { asPath, pathname, query } = Router.router
  mixpanelService.track(`page:${pathname}`, { asPath, query })
})

Router.events.on('routeChangeError', () => progressService.done())

// billing page url is the only one which is visible after account access revoked

const BILLING_REQUIRED_PAGES_ALLOWED = [
  '/admin/account/billing',
  '/auth/login/[authToken]',
]

class MyApp extends App {
  constructor() {
    super()
    // set globals for client side
    // set globals first so that its accessible by everything
    this.state = {
      snackbarShow: false,
      snackbarMessage: '',
      snackbarVariant: '',
      confirmShow: false,
      confirmTitle: '',
      confirmBody: '',
      confirmProceed: null, // will be resolve of promise
      confirmCancel: null, // will be reject of promise
      uniqueSnackbarId: null,
      confirmExtraOptions: {},
      browserSupported: CheckBrowserSupported(),
      accountMoved: false,
      accessRevoked: false,
      globals: {},
      globalsSet: true,
    }
    this.showAlert = this.showAlert.bind(this)
    this.confirm = this.confirm.bind(this)
    // setting showalert and confirm in mount can error when
    // they are triggered immediately on page load
    globalsService.setConfirm(this.confirm)
    globalsService.setShowAlert(this.showAlert)
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
    sentryService.init()
    sentryService.login(globalsService.getLoggedUser())

    // other checks (account, billing etc)

    GetGlobals().then(
      (globals) => {
        if (globals.school) this.setState({ globals })
        if (!globals.school) this.setState({ accountMoved: true })
        else if (globals.school && globals.school.accessRevoked) {
          // mark access revoked if plan expired
          this.setState({ accessRevoked: true })
        }
      },
      () => {
        this.setState({ globalsSet: false })
      }
    )
  }

  showAlert(message, variant) {
    this.setState({
      uniqueSnackbarId: randomString(20),
      snackbarMessage: message,
      snackbarVariant: variant,
      snackbarShow: true,
    })
  }

  confirm(title, body, extraOptions = {}) {
    const p = new Promise((resolve, reject) => {
      this.setState({
        confirmTitle: title,
        confirmBody: body,
        confirmShow: true,
        confirmProceed: resolve,
        confirmCancel: reject,
        confirmExtraOptions: extraOptions,
      })
    })
    return p
  }

  render() {
    const { Component, pageProps, router } = this.props
    const {
      snackbarMessage,
      snackbarShow,
      snackbarVariant,
      confirmBody,
      confirmCancel,
      confirmProceed,
      uniqueSnackbarId,
      confirmShow,
      confirmTitle,
      confirmExtraOptions,
      browserSupported,
      accountMoved,
      accessRevoked,
      globals,
      globalsSet,
    } = this.state
    const { query } = router
    const loggedUser = globalsService.getLoggedUser()
    const pagePath = router.pathname
    return (
      <div>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0, user-scalable=no"
          />
        </Head>
        <GlobalsContext.Provider value={globals}>
          <NProgressCSS />
          {globalsSet && !accountMoved && browserSupported && (
            <div>
              {!accessRevoked ||
              BILLING_REQUIRED_PAGES_ALLOWED.indexOf(pagePath) !== -1 ? (
                <Component {...pageProps} />
              ) : (
                <AccountAccessRevoked />
              )}
              {query && query.modal && (
                <div>
                  {query.modal === 'login' && !loggedUser && (
                    <LoginDialog
                      redirectUrl={query.redirectUrl}
                      onClose={CloseDialog}
                    />
                  )}
                </div>
              )}
              {snackbarShow && (
                <Snackbar
                  key={uniqueSnackbarId}
                  messageId={uniqueSnackbarId}
                  message={snackbarMessage}
                  open={snackbarShow}
                  onClose={() => this.setState({ snackbarShow: false })}
                  variant={snackbarVariant}
                />
              )}
              {confirmShow && (
                <ConfirmDialog
                  title={confirmTitle}
                  body={confirmBody}
                  proceed={() => {
                    confirmProceed()
                    this.setState({ confirmShow: false })
                  }}
                  cancel={() => {
                    confirmCancel()
                    this.setState({ confirmShow: false })
                  }}
                  {...confirmExtraOptions}
                />
              )}
            </div>
          )}
          {!browserSupported && <BrowserNotSupportedBlock />}
          {!globalsSet && <UnderMaintenanceBlock />}
          {accountMoved && <AccountMovedBlock />}
        </GlobalsContext.Provider>
      </div>
    )
  }
}

export default withRouter(MyApp)
